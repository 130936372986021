import React, { useEffect, useState } from 'react'
import { FormikErrors } from 'formik'
import { Soundboard, SoundboardGeneral } from 'common/api/soundboard/soundboard'

import useStores from 'common/hook/useStore'

import { SoundboardRoute } from 'soundboard'

import useImage from 'common/hook/useImage'
import { OptionType } from 'common/api/common/common'
import { defaultImg, toStyngTypeOptions } from 'common/constants'
import { Territory, TerritoryAvailability } from 'common/api/territory/territory'

import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import { TextField as MuiTextField } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import TextField from 'ui/TextField/TextField'
import Select from 'ui/Select/Select'
import Button, { ButtonVariant } from 'ui/Button/Button'

import Text from '../../../locale/strings'

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import styles from './General.module.scss'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

interface GeneralProps {
  values: SoundboardGeneral
  errors: FormikErrors<Soundboard>
  handleChange: (e: React.ChangeEvent<any>) => void
  handleSoundboardImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void
  styngTypes: any
  handleChangeSourceType: (selected: string) => void
  handleAvailability?: (selected: Territory[]) => void
  editFlow?: boolean
  handleSubmitGeneralInfo?: () => void
}

const General = ({
  editFlow,
  values,
  errors,
  styngTypes,
  handleChangeSourceType,
  handleSubmitGeneralInfo,
  handleChange,
  handleSoundboardImageUpload,
  handleAvailability,
}: GeneralProps) => {
  const { navigationStore } = useStores()

  const [selectedSoundboardCountriesArray, setSelectedSoundboardCountriesArray] = useState<Territory[]>([])
  const [availableSoundboardCountriesArray, setAvailableSoundboardCountriesArray] = useState<Territory[]>([])

  const { data } = useImage(values.imageId ?? '')
  const imageSoundboard = data?.url ?? defaultImg

  const [styngTypesOptions, setStyngTypesOptions] = useState<OptionType[]>([])

  useEffect(() => {
    if (styngTypes) {
      const allItems = styngTypes?.styngSourceTypes

      setStyngTypesOptions(allItems.map(toStyngTypeOptions) ?? [])
    }
  }, [styngTypes])

  useEffect(() => {
    if (typeof values.availability !== 'undefined') {
      const correctFormatForAutocomplete: Territory[] = []
      const initialSelectedValues: Territory[] = []

      values.availability.forEach(function (territory: TerritoryAvailability) {
        correctFormatForAutocomplete.push(territory)

        if (territory.isAvailable) {
          initialSelectedValues.push(territory)
        }
      })

      setAvailableSoundboardCountriesArray(correctFormatForAutocomplete)
      setSelectedSoundboardCountriesArray(initialSelectedValues)
    }
  }, [values])

  const handleAvailabilityChange = (e: React.SyntheticEvent, value: Territory[]) => {
    setSelectedSoundboardCountriesArray(value)
  }

  useEffect(() => {
    typeof handleAvailability !== 'undefined' && handleAvailability(selectedSoundboardCountriesArray)
  }, [selectedSoundboardCountriesArray])

  const handleDeleteCountry = (value: any) => {
    setSelectedSoundboardCountriesArray((countryArray) =>
      countryArray.filter((countryOption) => countryOption.territoryCode !== value),
    )
  }

  return (
    <div className={styles.infoWrapper}>
      <div className={styles.infoFields}>
        <TextField
          fullWidth
          data-test="name-field"
          label={Text.fields.name + ' *'}
          name="name"
          value={values.name}
          error={errors.name}
          inputProps={{ maxLength: 50 }}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          data-test="subscription-price-field"
          type="number"
          label={'Subscription price'}
          name="subscriptionPrice"
          value={values.subscriptionPrice}
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
          onKeyPress={(evt: any) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          data-test="styng-price-field"
          type="number"
          label={'Styng price'}
          name="styngPrice"
          value={values.styngPrice}
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
          onKeyPress={(evt: any) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
          onChange={handleChange}
        />
        {styngTypesOptions.length > 0 && (
          <Select
            fullWidth
            disabled={editFlow}
            data-test="source-type-field"
            className={styles.select}
            label={'Source type'}
            name="sourceType"
            value={values.sourceType}
            options={styngTypesOptions}
            onChange={handleChangeSourceType}
          />
        )}

        {editFlow && (
          <React.Fragment>
            {Object.keys(availableSoundboardCountriesArray).length !== 0 ? (
              <div className={styles.autocompleteWrapper}>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  data-test="available-countries-field"
                  id="availableCountries"
                  className={styles.autocompleteField}
                  isOptionEqualToValue={(option, value) => option.territoryCode === value.territoryCode}
                  value={selectedSoundboardCountriesArray}
                  options={availableSoundboardCountriesArray}
                  getOptionLabel={(labelOption: Territory) => labelOption.territoryCode}
                  renderOption={(props, renderOption, { selected }) => (
                    <li {...props}>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                      {renderOption.territoryName}
                    </li>
                  )}
                  renderTags={() => null}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      label={values.sourceType === 'LSR' ? 'Allowed countries' : 'Blocked countries'}
                      placeholder="Search"
                    />
                  )}
                  onChange={handleAvailabilityChange}
                />
                <Box className={styles.autocompleteFieldValues}>
                  {selectedSoundboardCountriesArray.map((item) => (
                    <Chip
                      className={styles.autocompleteFieldValue}
                      key={item.territoryCode}
                      label={item.territoryName}
                      onDelete={() => handleDeleteCountry(item.territoryCode)}
                    />
                  ))}
                </Box>
              </div>
            ) : (
              <p className={styles.autocompleteWrapper}>{Text.page.playlists.edit.noSubset}</p>
            )}
          </React.Fragment>
        )}
      </div>
      <div className={styles.uploadImage}>
        <img src={imageSoundboard} alt="soundboardPicture" loading="lazy" />
        <label htmlFor="file-input-id">
          <input
            hidden
            id="file-input-id"
            type="file"
            accept="image/*"
            value=""
            onChange={handleSoundboardImageUpload}
          />
          <Button isUpload data-test="upload-image-button" className={styles.uploadButton}>
            {Text.page.styngs.create.uploadCover}
          </Button>
        </label>
      </div>
      {editFlow && (
        <div className={styles.submitContainer}>
          <Button
            data-test="cancel-button"
            variant={ButtonVariant.OUTLINED}
            onClick={() => navigationStore.goToPage(SoundboardRoute.path)}
          >
            {Text.common.cancel}
          </Button>
          <Button data-test="submit-button" className={styles.saveButton} onClick={handleSubmitGeneralInfo}>
            {Text.common.save}
          </Button>
        </div>
      )}
    </div>
  )
}

export default General
