/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { Duration } from 'luxon'

import { Tooltip, Select, MenuItem, Paper, Box } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

import useStores from '../../../common/hook/useStore'

import API from '../../../common/api'
import { PlaylistTrack } from '../../../common/api/playlist/playlist'
import { TrackDownloadValues } from '../../../common/api/track/track'
import { FileType, ApiStatuses, categoriesTrackOptions } from '../../../common/constants'
import { formatItemsForCsvData } from '../../../common/utils'
import { useTracksInPlaylistQuery } from '../../pages/EditPlaylist/TracksInPlaylistStore'

import Table from '../../../ui/Table/Table'
import Button, { ButtonColor } from '../../../ui/Button/Button'
import CsvModal from '../../../ui/CsvModal/CsvModal'
import DeleteModal from '../../../ui/DeleteModal/DeleteModal'
import JsonDataModalWithPlatform from '../../../ui/JsonDataModalWithPlatform/JsonDataModalWithPlatform'
import LoadingComponent from '../../../ui/Loading/LoadingPage'

import Text from '../../../locale/strings'
import { TracksForRadioRoute } from '../../../tracks/pages'
import DownloadTrackModal from '../../../tracks/DownloadTrackModal/DownloadTrackModal'

import styles from './licensedPlaylistTable.module.scss'
interface PlaylistProps {
  appId: string | undefined
  playlistId: string
}

const LicensedPlaylistTable = ({ appId, playlistId }: PlaylistProps) => {
  const [playlistTracksForCsv, setPlaylistTracksForCsv] = useState<PlaylistTrack[]>([])
  const [mediaNetId, setMediaNetId] = useState<string>('')
  const [selectedTrack, setSelectedTrack] = useState<PlaylistTrack | null>(null)
  const [openCsvModal, setOpenCsvModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [jsonDataModal, setJsonDataModal] = useState<boolean>(false)

  const [showDownloadTrackModal, setShowDownloadTrackModal] = useState<boolean>(false)

  const [dataForTrackDownloadModal, setDataForTrackDownloadModal] = useState<TrackDownloadValues>({
    cover: '',
    mediaNetId: '',
    artist: '',
    title: '',
  })

  const { data, status: licensedTracksStatus, refetch } = useTracksInPlaylistQuery(playlistId)
  const licensedTracks = data?.tracks ?? []

  useEffect(() => {
    if (licensedTracksStatus === ApiStatuses.SUCCESS) {
      setPlaylistTracksForCsv([...licensedTracks].reverse())
    }
  }, [licensedTracksStatus])

  const { navigationStore, notificationsStore } = useStores()

  const handleChangeTrackCategory = (event: SelectChangeEvent<unknown>, track: PlaylistTrack) => {
    const selectedCategories = event.target.value

    changeSeedTrack.mutate({
      playlistId: playlistId,
      categories: selectedCategories,
      trackId: track.mediaNetId,
    })
  }

  const columns = [
    'Id',
    'Label',
    'Label Owner',
    'Availability',
    'Duration',
    'Artist IDs',
    'Artist Names',
    'Title',
    'Explicit',
    'Genre',
    'Album IDs',
    'Album Names',
    'BitRate',
    'Status',
    'External Metadata',
    'Track category',
    'Actions',
  ]

  const dataTable = licensedTracks.map((track: PlaylistTrack) => [
    track.mediaNetId,
    <Tooltip key={track.id} title={track.recordLabel}>
      <div
        style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          cursor: 'pointer',
        }}
      >
        {track.recordLabel}
      </div>
    </Tooltip>,
    <Tooltip key={track.id} title={track.recordLabelOwnerName}>
      <div
        style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          cursor: 'pointer',
        }}
      >
        {track.recordLabelOwnerName}
      </div>
    </Tooltip>,
    <Tooltip
      key={track.id}
      title={track.availability.map((el, i) => (i === track.availability.length - 1 ? `${el}` : `${el} | `))}
    >
      <div
        style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          cursor: 'pointer',
        }}
      >
        {track.availability.length === 0 ? '/' : track.availability.join(' | ')}
      </div>
    </Tooltip>,
    Duration.fromISO(track.duration).toFormat('mm:ss'),
    track.artistIds.length === 0 ? '/' : track.artistIds.join(' | '),
    track.artists.length === 0 ? (
      '/'
    ) : track.artists.length === 1 ? (
      track.artists.map((el) => `${el}`)
    ) : (
      <Tooltip title={track.artists.map((el) => `${el} | `)}>
        <div
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            cursor: 'pointer',
          }}
        >
          {track.artists.map((el) => `${el} | `)}
        </div>
      </Tooltip>
    ),
    track.title,
    track.parentalAdvisory ? 'Yes' : 'No',
    <Tooltip key={track.id} title={track.genre}>
      <div
        style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          cursor: 'pointer',
        }}
      >
        {track.genre}
      </div>
    </Tooltip>,
    track.albumIds.length === 0
      ? '/'
      : track.albumIds.length === 1
      ? track.albumIds.map((el) => `${el}`)
      : track.albumIds.map((el) => `${el} | `),
    <Tooltip key={track.id} title={track.albumName}>
      <div
        style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          cursor: 'pointer',
        }}
      >
        {track.albumName}
      </div>
    </Tooltip>,
    track.bitrate,
    track.status,
    Object.keys(track.externalMetadata).length === 0 ? 'NULL' : '',
    <Select
      multiple
      className={styles.customSelect}
      key={track.id}
      value={track.seedCategories}
      onChange={(e) => handleChangeTrackCategory(e, track)}
    >
      {categoriesTrackOptions.map((category) => (
        <MenuItem key={category.value} value={category.value}>
          {category.label}
        </MenuItem>
      ))}
    </Select>,
  ])

  const csvColumns = [
    'Id',
    'Label',
    'Availability',
    'Duration',
    'Artist IDs',
    'Artist Names',
    'Album IDs',
    'Album Names',
    'Title',
    'Explicit',
    'Genre',
    'Release Date',
    'BitRate',
    'Format',
    'Status',
    'Stream From',
    'Metadata',
  ]

  const csvData = playlistTracksForCsv.map((track: PlaylistTrack) => [
    formatItemsForCsvData(track.mediaNetId),
    formatItemsForCsvData(track.recordLabel),
    formatItemsForCsvData(track.availability),
    Duration.fromISO(track.duration).as('seconds'),
    formatItemsForCsvData(track.artistIds),
    formatItemsForCsvData(track.artists),
    formatItemsForCsvData(track.albumIds),
    formatItemsForCsvData(track.albumName),
    formatItemsForCsvData(track.title),
    track.parentalAdvisory ? 'Yes' : 'No',
    formatItemsForCsvData(track.genre),
    track.releaseDate === null
      ? 'NULL'
      : Duration.fromISO(track.releaseDate ?? undefined ? '' : track.releaseDate).toFormat('ff'),
    formatItemsForCsvData(track.bitrate),
    formatItemsForCsvData(track.format),
    formatItemsForCsvData(track.status),
    formatItemsForCsvData(track.streamFrom),
    Object.keys(track.externalMetadata).length === 0 ? 'NULL' : track.externalMetadata,
  ])

  const handleCloseCsvModal = () => {
    setOpenCsvModal(false)
  }

  const handleOpenCsvModal = () => {
    setOpenCsvModal(true)
  }

  const handleDeleteClick = (id: string) => {
    setMediaNetId(id)
    setShowDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setMediaNetId('')
    setShowDeleteModal(false)
  }

  const handleOpenJsonModal = (id: string) => {
    const track = licensedTracks.filter((track) => track.mediaNetId === id)

    setSelectedTrack(track[0])

    setJsonDataModal(true)
  }

  const handleCloseJsonModal = () => {
    setSelectedTrack(null)
    setJsonDataModal(false)
  }

  const onDeleteMutation = useMutation(
    () => {
      return API.playlist.deleteTrackFromPlaylist({
        playlistId: playlistId,
        trackId: mediaNetId,
      })
    },
    {
      onSuccess: () => {
        refetch()
        notificationsStore.successNotification('Track successfully deleted from playlist')
        setShowDeleteModal(false)
      },
      onError: (error: any) => {
        setShowDeleteModal(false)

        notificationsStore.errorNotification(error)
      },
    },
  )

  const handleDeleteTrack = () => {
    onDeleteMutation.mutate()
  }

  const changeSeedTrack = useMutation(
    (body: any) => {
      return API.playlist.setSeedTrack(body)
    },
    {
      onSuccess: () => {
        refetch()
        notificationsStore.successNotification('The list of selected categories has been successfully modified')
      },
      onError: (error) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const renderPlaylistTracks = () => {
    if (licensedTracks.length > 0) {
      return (
        <React.Fragment>
          <Paper elevation={3}>
            <Table
              hideEdit
              jsonData
              stickyHeader
              showDownloadTrack
              hiddenColumns={['External Metadata']}
              size="medium"
              sx={{
                tableLayout: 'fixed',
              }}
              columns={columns}
              data={dataTable}
              onDeleteClick={handleDeleteClick}
              onJsonDataClick={handleOpenJsonModal}
              onDownloadFile={handleDownloadTrackClick}
            />
          </Paper>
          <div className={styles.buttonsHolder}>
            <Button
              color={ButtonColor.INHERIT}
              onClick={() => {
                navigationStore.goToPage(TracksForRadioRoute.path)
              }}
            >
              {Text.page.tracks.tracks}
            </Button>
            <Button onClick={handleOpenCsvModal}>{Text.common.download}</Button>
          </div>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <p className={styles.emptyResponse}>There are no tracks added to this playlist</p>
        <div className={styles.buttonsHolder}>
          <Button
            color={ButtonColor.INHERIT}
            onClick={() => {
              navigationStore.goToPage(TracksForRadioRoute.path)
            }}
          >
            {Text.page.tracks.tracks}
          </Button>
        </div>
      </React.Fragment>
    )
  }

  const handleDownloadTrackClick = (id: string) => {
    const track = licensedTracks.filter((track) => track.mediaNetId === id)
    const currentTrack = track[0]

    const helperObjDataForTrackDownloadModal: TrackDownloadValues = {
      cover: '',
      mediaNetId: currentTrack.mediaNetId,
      artist: currentTrack.artists[0],
      title: currentTrack.title,
    }

    setDataForTrackDownloadModal(helperObjDataForTrackDownloadModal)
    setShowDownloadTrackModal(true)
  }

  const handleCloseModal = () => {
    setDataForTrackDownloadModal({
      cover: '',
      mediaNetId: '',
      artist: '',
      title: '',
    })

    setShowDownloadTrackModal(false)
  }

  return (
    <div className={styles.playlistEdit}>
      <Choose>
        <When condition={licensedTracksStatus === ApiStatuses.LOADING}>
          <LoadingComponent />
        </When>
        <When condition={licensedTracksStatus === ApiStatuses.SUCCESS}>
          <Box className={styles.sectionWrapper}>
            <div>{renderPlaylistTracks()}</div>

            {openCsvModal && (
              <CsvModal
                open={openCsvModal}
                dataTable={csvData}
                columns={csvColumns}
                handleClose={handleCloseCsvModal}
              />
            )}

            <DownloadTrackModal
              open={showDownloadTrackModal}
              handleClose={handleCloseModal}
              dataForTrackDownloadModal={dataForTrackDownloadModal}
            />

            {showDeleteModal && (
              <DeleteModal
                open={showDeleteModal}
                headerContent={'Remove song from playlist'}
                handleClose={handleCloseDeleteModal}
                text={'Are you sure you want to remove this song from playlist ?'}
                handleSubmit={handleDeleteTrack}
              />
            )}

            {jsonDataModal && (
              <JsonDataModalWithPlatform
                appId={appId}
                dataTypeId={selectedTrack?.mediaNetId}
                data={selectedTrack?.externalMetadata}
                dataType={FileType.TRACK}
                open={jsonDataModal}
                refetch={refetch}
                handleClose={handleCloseJsonModal}
              />
            )}
          </Box>
        </When>
        <When condition={licensedTracksStatus === ApiStatuses.ERROR}>
          <div>{Text.errors.generalErrorMessage}</div>
        </When>
      </Choose>
    </div>
  )
}

export default LicensedPlaylistTable
