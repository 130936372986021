import React, { useEffect } from 'react'
import * as R from 'ramda'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useMutation, useQueryClient } from 'react-query'
import { Duration } from 'luxon'

import useStores from '../../common/hook/useStore'

import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'

import Spinner from '../../ui/Spinner/Spinner'
import Button, { ButtonColor, ButtonVariant } from '../../ui/Button/Button'
import TextField from '../../ui/TextField/TextField'
import Select from '../../ui/Select/Select'

import API from '../../common/api'
import Text from '../../locale/strings'
import { useNftQuery } from '../../styngs/NftModal/NftStore'
import { EditNftRequest, EditNftResponse } from '../../common/api/styng/styng'
import { ImageEntity } from '../../common/api/image/image'
import { defaultImg, toAppOptions } from '../../common/constants'
import useImage from '../../common/hook/useImage'
import { OptionType } from '../../common/api/common/common'
import { useCreateImageMutation } from '../../common/hook/createImage'

import CreateNftStore from './NftStore'
import styles from './nftModal.module.scss'

const schema = yup.object({
  nftName: yup.string().max(50, 'NFT name must be at most 50 characters').required('NFT name is required'),
  genre: yup.string().required('Genre is required'),
  application: yup.string().required('Please bind string with application to continue with saving'),
})

interface NftFormValues {
  id: string
  nftName: string
  genreId: number
  album: string
  artist: string
  duration: string
  imageId: Nullable<string>
  application: string
  label: string
  trackTitle: string
  trackId: string
  genre: string
}

const initialValues: NftFormValues = {
  id: '',
  nftName: '',
  album: '',
  artist: '',
  duration: '',
  genreId: 0,
  genre: '',
  imageId: null,
  application: '',
  label: '',
  trackTitle: '',
  trackId: '',
}

interface NftProps {
  nftStore: CreateNftStore
  styngId: string
  open: boolean
  handleClose: () => void
  refetch(): void
}

const NftModal = ({ nftStore, open, styngId, handleClose, refetch }: NftProps) => {
  const queryClient = useQueryClient()
  const { notificationsStore } = useStores()
  const { data: initialStyng } = useNftQuery(styngId)

  const appOptions: OptionType[] = initialStyng ? initialStyng.applications.map(toAppOptions) : []

  const mutationNft = useMutation<EditNftResponse, Error, EditNftRequest>(
    (body: EditNftRequest) => {
      return API.nfts.create(body)
    },
    {
      onSuccess: () => {
        handleClose()
        queryClient.invalidateQueries('nfts')
        refetch()
        notificationsStore.successNotification('NFT draft successfully added')
      },
      onError: (error: any) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const mutationImage = useCreateImageMutation((data: ImageEntity) => {
    formik.setFieldValue('imageId', data.id)
  })

  const handleSubmit = async (values: NftFormValues) => {
    const sendForm = (imageId: Nullable<string>) => {
      const body = {
        styngId: values.id,
        nftName: values.nftName,
        artist: values.artist,
        album: values.album,
        duration: values.duration,
        genreId: values.genreId,
        trackId: initialStyng?.trackId,
        imageId: imageId,
        applicationId: values.application,
        trackTitle: values.trackTitle,
        label: values.label,
      }

      mutationNft.mutate(body)
    }

    const isFormValid = !R.isNil(initialStyng)

    if (isFormValid) {
      if (!R.isNil(values.imageId)) {
        sendForm(values.imageId)

        return
      }
    }
  }

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]

      if (file.type.includes('image/')) {
        const formData = new FormData()

        formData.append('file', file)

        mutationImage.mutate(formData)
      }
    }
  }

  const formik = useFormik<NftFormValues>({
    initialValues: initialValues,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  })

  const { values, resetForm, setFieldValue } = formik
  const { data, status } = useImage(values.imageId || '')
  const imageStyng = data?.url ?? nftStore?.currentTrack?.imageUrl ?? defaultImg

  useEffect(() => {
    if (!R.isNil(initialStyng)) {
      resetForm({
        values: {
          id: initialStyng.id,
          nftName: initialStyng.nftName ?? '',
          album: initialStyng.album,
          artist: initialStyng.artist,
          duration: initialStyng.duration,
          genreId: initialStyng.genres[0]?.id ?? '',
          genre: initialStyng.genres[0]?.name ?? '',
          imageId: initialStyng.imageId,
          application: '',
          label: initialStyng.label,
          trackTitle: initialStyng.trackTitle,
          trackId: initialStyng.trackId,
        },
      })
    }
  }, [initialStyng, resetForm])

  const handleChangeApp = (value: string) => {
    setFieldValue('application', value)
  }

  return (
    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box>
        {status === 'loading' ? (
          <Spinner />
        ) : (
          <div className={styles.container}>
            <div className={styles.header}>{'Create NFT'}</div>
            <div className={styles.innerContainer}>
              <TextField
                required
                label={Text.page.styngs.create.fields.nftName}
                name="nftName"
                value={values.nftName}
                error={formik.errors.nftName}
                onChange={formik.handleChange}
              />
              <TextField
                label={Text.page.styngs.create.fields.album}
                name="album"
                value={values.album}
                disabled={true}
                className={styles.input}
                error={formik.errors.album}
                onChange={formik.handleChange}
              />
              <TextField
                label={Text.page.styngs.create.fields.artist}
                name="artist"
                value={values.artist}
                disabled={true}
                className={styles.input}
                error={formik.errors.artist}
                onChange={formik.handleChange}
              />
              <div className={styles.row}>
                <TextField
                  fullWidth
                  label={Text.page.styngs.create.fields.recordLabel}
                  name="label"
                  value={values.label}
                  disabled={true}
                  className={styles.input}
                  error={formik.errors.label}
                  onChange={formik.handleChange}
                />
                <TextField
                  fullWidth
                  label={Text.page.styngs.create.fields.songName}
                  name="trackTitle"
                  value={values.trackTitle}
                  disabled={true}
                  className={styles.short}
                  error={formik.errors.trackTitle}
                  onChange={formik.handleChange}
                />
              </div>
              <div className={styles.row}>
                <TextField
                  fullWidth
                  label={Text.page.styngs.create.fields.duration}
                  name="duration"
                  value={Duration.fromISO(values.duration).toFormat('mm:ss')}
                  disabled={true}
                  className={styles.input}
                  error={formik.errors.duration}
                  onChange={formik.handleChange}
                />
                <TextField
                  fullWidth
                  label={Text.page.styngs.create.fields.genre}
                  name="genre"
                  value={values.genre}
                  disabled={true}
                  className={styles.short}
                  error={formik.errors.genre}
                  onChange={formik.handleChange}
                />
              </div>
              <div className={styles.row}>
                <Select
                  fullWidth
                  required
                  disabled={appOptions.length === 0}
                  label={Text.page.styngs.create.fields.application}
                  name="application"
                  value={values.application}
                  options={appOptions ?? []}
                  error={formik.errors.application}
                  onChange={handleChangeApp}
                />
              </div>
              {appOptions?.length === 0 && (
                <p className={styles.helpText}>{'Please bind styng with application to be able to make NFT draft'}</p>
              )}
              <div className={styles.uploadImage}>
                <img src={imageStyng} alt="styngImage" />
                <label htmlFor="file-image-id">
                  <input hidden id="file-image-id" type="file" accept="image/*" value="" onChange={handleImageUpload} />
                  <Button isUpload>{Text.page.styngs.create.uploadCover}</Button>
                </label>
              </div>
            </div>

            <div className={styles.submitContainer}>
              <Button variant={ButtonVariant.OUTLINED} onClick={handleClose}>
                {Text.common.cancel}
              </Button>
              <Button
                disabled={!formik.dirty || mutationNft.isLoading}
                loading={mutationNft.isLoading}
                color={!formik.dirty ? ButtonColor.SECONDARY : ButtonColor.PRIMARY}
                onClick={formik.handleSubmit}
              >
                {Text.common.save}
              </Button>
            </div>
          </div>
        )}
      </Box>
    </Modal>
  )
}

export default NftModal
