/* eslint-disable jsx-control-statements/jsx-for-require-each */
import React, { useEffect, useState } from 'react'
import * as R from 'ramda'
import { observer } from 'mobx-react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { StynglistsRoute } from '../../index'

import useStores from 'common/hook/useStore'

import API from 'common/api'

import { Toolbar, Typography, Box, Divider } from '@mui/material'

import Text from 'locale/strings'
import Button, { ButtonVariant } from 'ui/Button/Button'
import SearchBar from 'ui/SearchBar/SearchBar'
import TextField from 'ui/TextField/TextField'
import Select from 'ui/Select/Select'
import FormStore from 'common/FormStore'
import { Styng } from 'common/api/styng/styng'
import StyngItem from '../../CreateStynglist/StyngItem/StyngItem'
import StyngsFilter from '../../CreateStynglist/StyngsFilter'
import { EditStynglistRequest, EditStynglistResponse } from 'common/api/stynglist/stynglist'
import { defaultImg } from 'common/constants'
import { ImageEntity } from 'common/api/image/image'
import useImage from 'common/hook/useImage'
import { useStynglistQuery } from '../../StynglistsStore'
import Spinner from 'ui/Spinner/Spinner'
import { useLoadMoreAppsQuery } from 'apps/ApplicationStore'
import { OptionType } from 'common/api/common/common'
import { Application } from 'common/api/app/app'
import { useCreateImageMutation } from 'common/hook/createImage'

import styles from './stynglist.module.scss'

const schema = yup.object({
  title: yup.string().max(50, 'Title must be at most 50 characters').required('Title is required'),
  imageId: yup.string().required(),
})

interface StyngFormValues {
  title: string
  applicationId: string
  imageId: Nullable<string>
}

const initialValues: StyngFormValues = {
  title: '',
  applicationId: '',
  imageId: null,
}

interface ParamProps {
  id?: string
}

const toAppsOptions = (item: Application): OptionType => ({
  label: item.name,
  value: item.id,
})

const Stynglist = () => {
  const { navigationStore, notificationsStore, styngsStore } = useStores()
  const formStore = new FormStore()
  const { id = '' } = useParams<ParamProps>()
  const [filter, setFilter] = useState<string>('')
  const queryClient = useQueryClient()
  const [initialStyngs, setInitialStyngs] = useState<Styng[]>([])
  const { data: styngData, status, refetch: styngRefetch } = useStynglistQuery(id)
  const [currentApp, setCurrentApp] = useState<string>('')
  const [currentStyng, setCurrentStyng] = useState<Styng | null>(null)
  const [applicationsData, setApplicationsData] = useState<OptionType[]>([])

  const { data: appsLoadMore, fetchNextPage: fetchNextAppPage } = useLoadMoreAppsQuery()

  useEffect(() => {
    const allItems = appsLoadMore?.pages.flatMap((page) => page.apps)

    setApplicationsData(allItems?.map(toAppsOptions) ?? [])
  }, [appsLoadMore])

  const prepareStyng = () => {
    const styngImageId = styngData?.imageId
    const styngi = styngData?.styngs.filter((item) => item.imageId === styngImageId)

    if (styngi) {
      setCurrentStyng(styngi?.[0])
    }
  }

  React.useEffect(() => prepareStyng(), [styngData])

  const initialStynglist = id !== '' ? styngData : null

  const mutationStynglist = useMutation<EditStynglistResponse, Error, EditStynglistRequest>(
    (body: EditStynglistRequest) => {
      if (!R.isNil(initialStynglist)) {
        return API.stynglist.put({ ...body, id: initialStynglist?.id })
      }

      return API.stynglist.create(body)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('stynglists')
        navigationStore.goToPage(StynglistsRoute.path)

        !R.isNil(initialStynglist)
          ? notificationsStore.successNotification('Stynglists successfully edited')
          : notificationsStore.successNotification('Stynglists successfully added')
      },
      onError: (error: any) => {
        notificationsStore.errorNotification(error)
      },
    },
  )

  const mutationImage = useCreateImageMutation((data: ImageEntity) => {
    setCurrentStyng(null)
    formik.setFieldValue('imageId', data.id)
  })

  const handleSubmit = async (values: StyngFormValues) => {
    formStore.handleSubmit(async () => {
      const body = {
        title: values.title.trim(),
        imageId: values.imageId,
        applicationId: values.applicationId === '' ? null : values.applicationId,
        styngs: R.map(R.prop('id'), initialStyngs),
      }

      mutationStynglist.mutate(body)
    })
  }

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]

      if (file.type.includes('image/')) {
        const formData = new FormData()

        formData.append('filename', file.name)
        formData.append('file', file)

        mutationImage.mutate(formData)
      }
    }
  }

  const formik = useFormik<StyngFormValues>({
    initialValues: initialValues,
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  })

  const { values, setFieldValue } = formik
  const { data } = useImage(values.imageId ?? '')

  const imageStynglist = data?.url ?? defaultImg

  useEffect(() => {
    if (!R.isNil(initialStynglist)) {
      formik.resetForm({
        values: {
          title: initialStynglist.name,
          applicationId: initialStynglist.applicationId ?? '',
          imageId: initialStynglist.imageId,
        },
      })

      setInitialStyngs(initialStynglist?.styngs)
      setCurrentApp(initialStynglist.applicationId ?? '')
    }
  }, [initialStynglist])

  const handleClickStyng = (styng: Styng) => {
    //logic to prevent adding same styngs to the list
    let itemExist = false

    initialStyngs.map((e) => {
      if (e.id === styng.id) {
        return (itemExist = true)
      }

      return null
    })

    if (itemExist) {
      notificationsStore.infoNotification('Styng is already added to the list', 2500)

      return
    }

    setInitialStyngs([...initialStyngs, styng])
  }

  const handleSelectStyng = (styng: Styng) => {
    setCurrentStyng(styng)
    formik.setFieldValue('imageId', styng.imageId)
  }

  const handleDeleteStyng = (id: string) => {
    const newStyngs = initialStyngs.filter((item) => item.id !== id)

    setInitialStyngs(newStyngs)
  }

  const handleSearch = (value: string) => {
    styngsStore.search(value)
  }

  const handleCancel = () => {
    navigationStore.goToPage(StynglistsRoute.path)
  }

  const handleAppChange = (app: string) => {
    setCurrentApp(app)
    setFieldValue('applicationId', app)
  }

  return (
    <Box>
      {status === 'loading' ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <Toolbar className={styles.toolbar}>
            <Typography sx={{ ml: 2, flex: 1, color: '#fff' }} variant="h6" component="div">
              {!R.isNil(initialStynglist) ? 'Edit stynglist' : 'Add stynglist'}
            </Typography>
          </Toolbar>
          <div className={styles.container}>
            <div className={styles.row}>
              <div className={styles.col}>
                <TextField
                  fullWidth
                  data-test="title-field"
                  label={Text.page.stynglists.create.stynglistTitle}
                  name="title"
                  value={values.title}
                  error={formik.errors.title}
                  onChange={formik.handleChange}
                />
                <Select
                  fullWidth
                  clear
                  data-test="application-field"
                  label={'Application'}
                  value={values.applicationId}
                  options={applicationsData}
                  className={styles.select}
                  error={formik.errors.applicationId}
                  handleloadMoreItems={fetchNextAppPage}
                  onChange={handleAppChange}
                />
              </div>
              <div className={styles.uploadImage}>
                <img src={imageStynglist} alt="stynglistPicture" loading="lazy" />
                <label htmlFor="file-input-id">
                  <input
                    hidden
                    data-test="upload-file-button"
                    id="file-input-id"
                    type="file"
                    accept="image/*"
                    value=""
                    onChange={handleFileUpload}
                  />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button isUpload type="button">
                      {Text.page.styngs.create.uploadCover}
                    </Button>
                    {formik.errors.imageId && imageStynglist === defaultImg ? (
                      <div className={styles.errMessage}>{'Image is required'}</div>
                    ) : null}
                  </div>
                </label>
              </div>
            </div>

            <Divider variant="middle" />

            <div className={styles.subContainer}>
              <div className={styles.leftColumn}>
                <SearchBar
                  label={Text.page.stynglists.searchPlaceholder}
                  filter={filter}
                  onChangeFilter={setFilter}
                  onSearch={handleSearch}
                >
                  {!R.isEmpty(filter) ? <StyngsFilter name={filter} onClickStyng={handleClickStyng} /> : <div />}
                </SearchBar>
              </div>
              <div className={styles.rightColumn}>
                {initialStyngs.length !== 0 ? (
                  initialStyngs.map((styng) => {
                    return (
                      <StyngItem
                        appId={currentApp}
                        key={styng.id}
                        styng={styng}
                        refetch={styngRefetch}
                        selectedId={currentStyng?.id}
                        onSelect={handleSelectStyng}
                        onDelete={handleDeleteStyng}
                      />
                    )
                  })
                ) : (
                  <p className={styles.noSelectedStyng}>There is no selected Styng</p>
                )}
              </div>
            </div>
            <div className={styles.submitContainer}>
              <Button data-test="cancel-button" variant={ButtonVariant.OUTLINED} onClick={handleCancel}>
                {Text.common.cancel}
              </Button>
              <Button data-test="submit-button" loading={formStore.loading} onClick={formik.handleSubmit}>
                {Text.common.save}
              </Button>
            </div>
          </div>
        </React.Fragment>
      )}
    </Box>
  )
}

export default observer(Stynglist)
